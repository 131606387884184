<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card style="margin-bottom: 30px">
          <v-card-title>Table Pricing</v-card-title>

          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Path Icon</th>
                <th scope="col">Name</th>
                <th scope="col">Regular</th>
                <th scope="col">Deep Cleaning</th>
                <th scope="col">Move inOut</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pricing in pricing" :key="pricing.id">
                <th scope="row">{{ pricing.id }}</th>
                <td style="width: 20%">
                  <img :src="'https://clenacity.krueltech.com/dev' + pricing.path_icon" style="width: 20%" />
                </td>
                <td>{{ pricing.name }}</td>
                <td>
                  <input type="text" v-model="pricing.unitary_value" name="unitary_value" />
                </td>
                <td>
                  <input type="text" v-model="pricing.unitary_value_deep" name="unitary_value_deep" />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="pricing.unitary_value_move_in"
                    name="unitary_value_move_in"
                  />
                </td>
              </tr>
            </tbody>
          </table>

          <b-button type="button" @click="post()" variant="primary" style="width: 100%">Update</b-button>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "table-pricing",
  methods: {
    post() {
      axios({
        method: "post",
        url: "https://cleancity.krueltech.com/dev/api/table-pricing.php",
        data: JSON.stringify(this.pricing),
        config: { headers: { "Content-Type": "multipart/form-data" } }
      })
        .then(function(response) {
          //handle success
          alert("Price list updated successfully!");
          console.log("foi: " + response.data);
        })
        .catch(function(response) {
          alert("Error!");
          console.log("nao foi: " + response);
        });
    }
  },
  data() {
    return {
      pricing: [],
      pricingAtual: []
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readPricing")
      .then(res => res.json())
      .then(data => {
        this.pricing = data.pricing;
        this.pricingAtual = data.pricing;
        console.log(data.pricing);
      })
      .catch(console.log);
  }
};
</script>
